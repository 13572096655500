<template>
  <div
    class="group relative hover:z-10"
    :class="{ 'cursor-none': $slots.label }"
  >
    <div ref="wrapper" class="h-full w-full">
      <slot />
    </div>

    <div
      v-if="$slots.label && !isOutside"
      class="absolute left-[--posX] top-[--posY] hidden group-hover:block"
      :style="{
        '--posX': `${elementX}px`,
        '--posY': `${elementY}px`,
        '--color': color,
      }"
    >
      <app-icon
        class="-translate-x-3 text-[--color]"
        icon="fluent:cursor-16-filled"
      />

      <div
        class="whitespace-nowrap rounded bg-[--color] px-2 py-1 text-white transition-colors"
      >
        <slot name="label" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const wrapper = templateRef<HTMLElement>("wrapper");
const { isOutside, elementY, elementX } = useMouseInElement(wrapper);

withDefaults(
  defineProps<{
    color?: string;
  }>(),
  {
    color: "#000",
  },
);
</script>
