<template>
  <nuxt-link
    class="group block transition active:scale-[.97]"
    :style="{
      width: `${width}px`,
    }"
    :to="{
      name: 'catalog-index-categories-slug',
      params: { slug: page.tag.slug },
    }"
    @click="onClick"
  >
    <app-card
      class="flex h-full flex-col items-center gap-4 rounded-xl p-4 text-center transition-all hover:-translate-y-1"
      outlined
      :style="{
        backgroundColor: hexToHSL(page.tag.color, {
          luminance: 97,
          saturation: 60,
        }),
        borderColor: hexToHSL(page.tag.color, {
          luminance: 85,
          saturation: 50,
        }),
      }"
    >
      <div
        class="grid aspect-square w-24 place-content-center bg-[--bgColor] transition-all clip-path-[--shape] group-hover:clip-path-[--hoverShape]"
        :style="{
          '--bgColor': bgColor,
          '--iconColor': iconColor,
          '--shape': shape,
          '--hoverShape': hoverShape,
        }"
      >
        <app-icon
          v-if="page.tag.icon"
          class="text-[--iconColor]"
          :icon="page.tag.icon"
          :size="50"
        />
      </div>

      <div class="flex-auto">
        <p class="text-xl font-bold">
          {{ page.tag.label }}
        </p>
        <p class="mt-1 text-sm">
          {{ page.tag.total_number_of_courses }} formations
        </p>
      </div>
    </app-card>
  </nuxt-link>
</template>

<script lang="ts" setup>
import { hexToHSL } from "@pollen/core/client/utils/color.utils";

import * as BrandShapeRegistry from "~/business-areas/brand/brand-shapes";
import type { TagPageListView } from "~/business-areas/tag-page/composables/tag-page-list.hook";
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";
import type { TagListOrigin } from "~~/model/analytics.model";

const properties = defineProps<{
  origin: TagListOrigin;
  page: TagPageListView;
}>();

const bgColor = computed(() =>
  hexToHSL(properties.page.tag.color, { luminance: 90 }),
);
const iconColor = computed(() =>
  hexToHSL(properties.page.tag.color, { luminance: 30 }),
);

const shape = computed(() => {
  const shapes = Object.values(BrandShapeRegistry);
  const firstSlugLetter = properties.page.tag.slug.charAt(0);

  const shapeIndex = Math.floor(
    ("abcdefghijklmnopqrstuvwxyz".indexOf(firstSlugLetter) * shapes.length) /
      26,
  );

  return shapes[shapeIndex];
});

const hoverShape = computed(() => {
  return pickRandomElementInArray(
    Object.values(BrandShapeRegistry).filter((item) => item !== shape.value),
  );
});

const { $analytics } = useNuxtApp();
const onClick = () =>
  $analytics.track("course_list_clicked", {
    origin: properties.origin,
    tags: [properties.page.tag.slug],
  });

const width = inject<CarouselProvidedState | null>(
  CarouselProvideKey,
  null,
)?.itemWidth;
</script>
